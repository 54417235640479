import { GameCategoryProps } from './props';

import { Game } from '@/models/game';

import { useGames } from '@/api/games';
import GameCard from '@/components/core/Games/GameCard/Versions/Primary';
import { Section } from '@package/components';

export default function GameCategories({ tag }: GameCategoryProps) {
  const {
    error,
    games,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
    metadata,
  } = useGames({
    tag,
    limit: 20,
  });

  return (
    <Section
      data={games as Game[]}
      limit={20}
      paginated
      paginationType="manual"
      error={error}
      hasMore={metadata?.hasNextPage}
      isLoading={isLoading}
      isLoadingMore={isFetchingNextPage}
      loadMore={fetchNextPage}
      refetch={refetch}
      totalCount={metadata?.count}
      componenents={{
        component: (game, position) => (
          <GameCard
            game={game}
            key={`game-card-section-${tag}-${game?.id}`}
            priority={position < 10}
          />
        ),
        skeleton: index => (
          <GameCard
            isLoading
            key={`game-card-section-${tag}-${index}-skeleton`}
          />
        ),
      }}
    />
  );
}
