import { useCallback, useEffect, useState } from 'react';
import { UseStoriesProps } from './props';
import { useCountUp } from 'use-count-up';
import { useRouter } from 'next/router';
import { usePathname } from '../usePathname';
import { useSearchParams } from '../useSearchParams';

export function useStories({ stories }: UseStoriesProps) {
  const search = useSearchParams();
  const item = search.get('item') ?? 0;
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);
  const minSwipeDistance: number = 50;
  const [current, setCurrent] = useState<number>(Number(item));
  const { push } = useRouter();
  const pathname: string = usePathname();

  const handleNextStory = useCallback(() => {
    if (current == stories.length - 1) {
      push(pathname.split('?')[0]);
      return;
    }

    setCurrent(prev => prev + 1);
  }, [current, stories, pathname, push]);

  const handlePreviousStory = useCallback(() => {
    if (current == 0) {
      return;
    }

    setCurrent(prev => prev - 1);
  }, [current]);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartX(e.targetTouches[0].clientX);
    setTouchEndX(null);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEndX(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX === null || touchEndX === null) return;

    const distance: number = touchStartX - touchEndX;
    const isSwipe: boolean = Math.abs(distance) > minSwipeDistance;

    if (!isSwipe) return;

    if (distance > 0) {
      handleNextStory();
    } else {
      handlePreviousStory();
    }
  };

  const { value, reset } = useCountUp({
    decimalPlaces: 2,
    duration: 5,
    start: 5,
    isCounting: true,
    updateInterval: 0,
    end: 0,
    easing: 'linear',
    onComplete: () => handleNextStory(),
  });

  function handleReset() {
    if (typeof window == 'undefined') {
      return;
    }
    reset();
  }

  useEffect(handleReset, [current, reset]);

  return {
    current,
    handleTouchEnd,
    handleNextStory,
    handleTouchMove,
    handleTouchStart,
    countdown: value,
    handlePreviousStory,
  };
}
